import { Injectable } from '@angular/core';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor(private sweetAlert2Loader: SweetAlert2LoaderService) { }

  async showError(title: string, message: string) {
    const swal = await this.sweetAlert2Loader.swal;
    const options: SweetAlertOptions = {
      icon: 'error',
      title,
      text: message,
      confirmButtonText: 'OK'
    };

    return swal.fire(options);
  }

  async showConfirm(title: string, message: string) {
    const swal = await this.sweetAlert2Loader.swal;
    const options: SweetAlertOptions = {
      icon: 'success',
      title,
      text: message,
      confirmButtonText: 'OK'
    };

    return swal.fire(options);
  }

  async showInfo(title: string, message: string) {
    const swal = await this.sweetAlert2Loader.swal;
    const options: SweetAlertOptions = {
      icon: 'info',
      title,
      text: message,
      confirmButtonText: 'OK'
    };

    return swal.fire(options);
  }

  async showCustom(options: SweetAlertOptions) {
    const swal = await this.sweetAlert2Loader.swal;

    return swal.fire(options);
  }


}
