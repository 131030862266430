import { ProductService } from '../services/product.service';
import { CommandsService } from '../services/commands.service';
import { AbstractPollResultStore } from './poll-result-abstract.store';

export class FrankePollResultStore extends AbstractPollResultStore {

  transaction_id: any;

  constructor(
    productService: ProductService,
    commandsService: CommandsService,
  ) {
    super(productService,commandsService);
  }

  public poll(){
    console.log('SUBSCRIBING POLL STATUS');
    const sub  =
    this.commandsService.getFrankeStatus( this.transaction_id, this.productService.token )
    .subscribe(
      (response) => this.processResult(response),
      (error) => this.processError(error),
      ()=>{
        console.log('SUBSCRIBING POLL STATUS - UNSUBSCRIBE');
        sub?.unsubscribe();
      }
    )
  }
}
