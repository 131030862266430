<div class="container pt-2 pt-md-4">
  <div class="single-text-row mb-2 mb-md-4 mt-5">
    <h1 class="error">Something went wrong!</h1>
    <p>Please, scan the new QR code on the machine and retry.</p>
    <ng-container *ngTemplateOutlet="detailTemplate; context:{error:error$ | async}"></ng-container>
  </div>
</div>

<ng-template #detailTemplate let-error="error">
  <p *ngIf="error" class="pt-5">[ERR-CODE:{{error.transaction_status}}]</p>
  <p *ngIf="error">{{error.mapping?.message}}</p>
</ng-template>
