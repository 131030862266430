import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdyenCheckoutStore {

  private readonly loading = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this.loading.asObservable();

  private readonly complete = new Subject<any>();
  public readonly complete$ = this.complete.asObservable();

  private readonly error = new Subject<any>();
  public readonly error$ = this.error.asObservable();

  constructor() { }

  public lastPspConfiguration: any;

  public startCheckoutProcess(data: any) {
    console.log('startCheckoutProcess data:',data);
    const environment = data.merchant_info.environment_configuration;
    const clientKey = data.merchant_info.merchant_client_key;
    const sessionData = data.psp_response.sessionData;
    const id = data.psp_response.id;
    const dropincfg = {
      environment, // Change to 'live' for the live environment.
      clientKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      session: {
        id, // Unique identifier for the payment session.
        sessionData
      },
      paymentMethodsConfiguration: {
        paywithgoogle: {
          configuration: {
            merchantName: data.merchant_info.merchant_name,
            gatewayMerchantId: data.merchant_info.merchant_name,
            merchantId: data.merchant_info.googlepay_merchant_id//'08731541460744986930'
          }
        },
        applepay: {
          // configuration: {
          //   buttonType:'buy',
          //   buttonColor:'black',
          //   total: {
          //     label: "Demo (Card is not charged)",
          //     type: "final",
          //     amount: "1.99"
          //   }
          // }
        }
      }
    };
    console.log('OPEN DROP IN WITH CONFIG:', dropincfg);
    return {
      ...dropincfg,
      onPaymentCompleted: (result, component) => {
        console.log('PAYMENT COMPLETE', result);
        if (result?.resultCode === 'Authorised') {
          console.log(`PAYMENT COMPLETE the result code is ${result?.resultCode}`);
          console.log('Payment result is:',result);
          console.log('component is:',component);
          this.complete.next(result);
        } else {
          console.log(`PAYMENT COMPLETE but not authorised the result code is ${result?.resultCode}`);
          console.log('Payment result is:',result);
          console.log('component is:',component);
          this.error.next({message:`Payment failed ${result?.resultCode?'' + result?.resultCode + '':''}`});
        }
      },
      onError: (error, component) => {
        console.error('PAYMENT ERROR');
        console.error('Payment error is:',error);
        console.error('component is:',component);
        this.error.next(error);
      }
    }
  }

}
