import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SwalService } from '../services/swal-service.service';
import { Router } from '@angular/router';
import { appRoutesNames } from '../app-routing.names';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private swalService: SwalService,
    private router: Router,
   ) {}

  static readonly DEFAULT_ERROR_MESSAGE: string = 'Something went wrong, please try again';
  static readonly UNAUTHORIZED_ERROR_MESSAGE: string = 'Unauthorized: access to this resource is denied';
  static readonly FORBIDDEN_ERROR_MESSAGE: string = 'You don\'t have permission to perform this action';

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //console.log('Error interceptor');
    return next.handle(request).pipe( catchError( err => {
      console.log('Error interceptor catch an error',err);
      // skip for polling alternative is  !( request.body?.command )
      if( request.method === 'GET' ){
        return throwError(err);
      } else {
        console.log('============INTERCEPTOR ERROR============');
        console.log(request);
        console.log(err);
        console.log('=========================================');

        const title = 'Error'
        const message = err?.error?.message ?? ErrorInterceptor.DEFAULT_ERROR_MESSAGE;

        this.swalService.showError(title, message).then(
          (r) => { if(r) this.navigateFailurePage() }
        )

        return throwError(err);
      }
    }));
  }

  navigateFailurePage(){
    this.router.navigate([`${appRoutesNames.failure}`]);
  }
}

/**
 * Provider for the interceptor
 */
export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};



