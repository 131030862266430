import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { CommandsService } from 'src/app/services/commands.service';
import { IProductStore, Machine, Menu, MenuItem, ProductChoice } from 'src/app/models/machine.model';
import { FrankePollResultStore } from './poll-result-franke.store';

const FrankeMenuNames = {
  recap: 'recap',
  beverageFamily:'beverageFamily',
  cupSize:'cupSize',
}

export class FrankeProductStore implements IProductStore {

  public machine_id: string;
  public vendor: number;
  public brand: string;

  defaults = {
    blendType : "CustomBlend1",
    extraShotEspresso : "ExtraShotEspressoNo",
    flavorType : "FlavorTypeNo"
  }

  families: any[];
  products: any[];
  currentFamily: any[];
  machineMenus: Menu[] = [];

  private readonly currentChoice = new BehaviorSubject<ProductChoice>(null);
  public readonly currentChoice$ = this.currentChoice.asObservable();

  private readonly currentMenu = new BehaviorSubject<Menu>(null);
  public readonly currentMenu$ = this.currentMenu.asObservable();

  private readonly loading = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this.loading.asObservable();

  private readonly result = new Subject<any>();
  public readonly result$ = this.result.asObservable();

  private readonly error = new Subject<any>();
  public readonly error$ = this.error.asObservable();

  private readonly paymentSessionData = new Subject<any>();
  public readonly paymentSessionData$ = this.error.asObservable();

  private readonly expiredToken = new BehaviorSubject<boolean>(false);
  public readonly expiredToken$ = this.expiredToken.asObservable();

  public statusChange$: Observable<any>;

  pollStore: FrankePollResultStore;

  private static instance: FrankeProductStore;

  public static getInstance(
    productService: ProductService,
    commandsService: CommandsService,
    machine: Machine
  ):FrankeProductStore{
      if( ! FrankeProductStore.instance )
      FrankeProductStore.instance = new FrankeProductStore(productService,commandsService,machine);
      return FrankeProductStore.instance;
  }

  private constructor(
    private productService: ProductService,
    private commandsService: CommandsService,
    private machine: Machine
  ) {
    machine.data.forEach( f => {
      f.children.forEach( c => {
        c.product_id = c.productId;
        delete c.productId;
      });
    });
    this.pollStore = new FrankePollResultStore(productService,commandsService);
    this.statusChange$ = this.pollStore.change$;

    this.machine_id = machine.machine_id;
    this.vendor = machine.vendor;
    this.brand = machine.brand;
    this.families = machine.data.map( f => { f.code = f.id; return f } );
    this.pollStore.result$.subscribe(
      r => {
        this.result.next(r);
        if( true )
          this.expiredToken.next( true );
      }
    );
    this.buildMenu(this.currentChoice.getValue());
  }

  public select(option:MenuItem, trasientChoice?:ProductChoice){
    const choice = trasientChoice ?? {...this.currentChoice.getValue()} ?? new ProductChoice();
    choice[option.type] = {...option};
    if( option.type === FrankeMenuNames.beverageFamily ){
      this.currentFamily = this.families.find( f => f.code === choice.beverageFamily.code ).children;
      this.buildCupSizeMenu(choice);
      this.notifyChoice(choice);
    } else if( option.type === FrankeMenuNames.cupSize){
      this.buildRecapMenu(choice);
    } else {
      this.notifyChoice(choice);
    }
  }

  public gotoMenu(code:string){
    let choice = this.currentChoice.getValue();
    switch (code) {
      case FrankeMenuNames.beverageFamily:
        choice = null;
        this.buildFamilyMenu(choice);
        this.notifyChoice(choice);
        break;
      case FrankeMenuNames.cupSize:
        this.buildCupSizeMenu(choice);
        break;
      case FrankeMenuNames.recap:
        this.buildRecapMenu(choice);
      default:
        this.currentMenu.next( this.machineMenus.find( m => m.code === code ) );
        break;
    }
  }

  public startErogation(){
    const product_id = this.currentChoice.getValue()?.product_id;
    if( ! product_id )
      return;

    console.log(`Start erogation of ${product_id}`);
    this.commandsService.startFrankeDispensing(product_id, this.productService.token).subscribe(
      result => this.checkResult(result),
      error => {
        console.log(`Error in start erogation of ${product_id}`,error?.error);
        this.error.next(error);
      }
    )
  }

  checkResult(result) {
    const product_id = this.currentChoice.getValue()?.product_id;
    const product = this.currentFamily.find( p => p.product_id === product_id );
    this.pollStore.check(result,product);
  }

  notifyChoice(choice){
    const selection = choice ? this.currentFamily.filter( p => this.filterByCurrentChoice(p,choice) ) : null;
    if( selection?.length === 1 ){
      choice.product_id = selection[0]?.product_id;
      console.log(`SELECTED PRODUCT ID ${choice.product_id}`);
    } else if( choice ) {
      choice.product_id = null;
      console.log(`MULTIPLE PRODUCT ID SELECTED ${selection?.length}`);
    }
    this.currentChoice.next( choice );
  }

  resetMachineMenu(){
    this.machineMenus = [{...this.machineMenus[FrankeMenuNames.beverageFamily]}];
  }

  buildMenu(choice:ProductChoice){
    if( ! choice?.beverageFamily ){
      this.buildFamilyMenu(choice);
    }
    else if( ! choice.cupSize ){
      this.buildCupSizeMenu(choice);
    }
    else this.buildRecapMenu(choice)
  }

  getMachineMenu ( code: string ){
    let  menu = this.machineMenus.find( m => m.code === code );
    if( ! menu ){
      menu = { code, options:[] };
      this.machineMenus.push( menu );
    }
    return menu;
  }

  getDefaultName(family:any){
    const result = family?.children?.length ? family.children[0].names?.default ?? family.name : family.name;
    return result;
  }

  buildFamilyMenu(choice:ProductChoice){
    this.resetMachineMenu();
    const menu = this.getMachineMenu(FrankeMenuNames.beverageFamily);
    menu.options = this.families.map( f => this.createOption( f.code, menu.code, this.getDefaultName(f), f.picture, f.available ) );
    console.log( menu );
    this.currentMenu.next(menu);
  }

  buildCupSizeMenu(choice:ProductChoice){
    const menu = { code:FrankeMenuNames.cupSize, back:FrankeMenuNames.beverageFamily, options:[]};
    for (const product of this.currentFamily) {
      const code =  product.filterSelection.cupSize;
      this.pushIfNotMatch( menu.options,  this.createOption(code,menu.code,code,product.images.cupSize ?? product.images.beverageFamily) );
    }

    menu.options = menu.options.sort((a,b)=>{return - a.code.localeCompare(b.code)});

    this.pushIfNotMatch( this.machineMenus, menu );

    const cupsizes = menu.options.length;
    if( cupsizes === 0 ){
      this.buildRecapMenu(choice);
    }
    else if( cupsizes === 1 ){
      this.select(menu.options[0], choice);
    }
    else {
      this.currentMenu.next(menu);
    }
  }

  buildRecapMenu(choice:ProductChoice){
    const cupsizes = this.machineMenus.find( m => m.code === FrankeMenuNames.cupSize )?.options?.length ?? 0;
    const recapmenu = { code:FrankeMenuNames.recap, back: cupsizes > 1 ? FrankeMenuNames.cupSize : FrankeMenuNames.beverageFamily, options:[]};
    const products = this.currentFamily.filter( p => this.filterByCurrentChoice(p,choice));

    for (const product of products) {
      for( const code in product.filterSelection ){
        const value = product.filterSelection[code];
        if( value && ! [FrankeMenuNames.beverageFamily,FrankeMenuNames.cupSize].includes(code) ){
          this.pushIfNotMatch( recapmenu.options, this.createOption( code, recapmenu.code, code, product.images[code]) );

          let subMenu = this.machineMenus.find( m => m.code === code );
          if( ! subMenu ){
            subMenu = { code, back: recapmenu.code, options: [] };
            this.pushIfNotMatch( this.machineMenus, subMenu );
          }
          const option = subMenu.options.find( o => o.code === value && o.type === code ) ?? this.createOption( value, code, value, product.images[code], false);
          this.pushIfNotMatch( subMenu.options, option);
          if( product.available === true )
            option.available = true;
        }
      }
    }
    recapmenu.options = recapmenu.options
      .filter( o => this.machineMenus.find( m => m.code === o.code )?.options?.length > 0)
      .sort((a,b)=>{return - b.code.localeCompare(a.code)})
      .map( o => {
        const submenu = this.machineMenus.find( m => m.code === o.code );
        console.log( submenu.options.map(o=>o.code+' '+o.code.endsWith('No') ));
        submenu.options = submenu.options.sort((a,b)=>{
          const _a = a.code.endsWith('No') ? '_' : a.code
          const _b = b.code.endsWith('No') ? '_' : b.code
          return _a.localeCompare(_b)
        });
        return { ...o, submenu };
      } );
    this.pushIfNotMatch( this.machineMenus, recapmenu );
    this.applyDefaults( choice );
    this.currentMenu.next( recapmenu );
  }

  filterByCurrentChoice(product,choice){
    const filterSelection = product.filterSelection;
    for (const key in filterSelection) {
      if( choice[key]?.code && filterSelection[key] !== choice[key].code){
        return false;
      }
    }
    return true;
  }

  applyDefaults( choice ) {
    const menus = this.machineMenus.filter( m => m.code !== FrankeMenuNames.recap );
    for (const menu of menus) {
      const code = menu.code;
      const distinct = [...new Set(this.currentFamily.map( f => f.filterSelection[code]))];
      const defaultValue = distinct?.length === 1 ? distinct[0] : this.defaults[code];
      const actualValue = choice[code]?.code;
      if( defaultValue && ! actualValue ){
        const defaultOption = menu.options.find( o => o.code === defaultValue );
        choice[code] = { ...defaultOption };
      }
    }
    this.notifyChoice( choice );
  }

  pushIfNotMatch( array:any[], item:any, key: string = 'code' ){
    if( array && item && ! array.find( i => i[key] === item[key] ) ) {
      array.push(item);
    }
  }

  createOption(code:string, type:string, label:string, picture:string, available:boolean = true) : MenuItem{
    return {code, type, label, picture, available};
  }

  startPayment() {
    throw new Error('Method not implemented.');
  }

  startPolling() {
    throw new Error('Method not implemented.');
  }

  stopPolling(){
    this.pollStore.cancel();
  }

  getCurrentChoice() {
    return this.currentChoice.getValue();
  }

}
