  <ng-container *ngIf="factory.currentStore$ | async">
    <ng-container *ngTemplateOutlet="storeLoaded; context:{store:factory.currentStore$ | async}"></ng-container>
  </ng-container>

  <ng-template #storeLoaded let-store="store">
    <ng-container *ngIf="store" [ngSwitch]="store.vendor">
      <ng-container *ngSwitchCase="IVendor.Vendon">
        <div class="container pt-2 pt-md-4">
          <app-vendon-new-menu></app-vendon-new-menu>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="IVendor.Franke">
        <div class="container p-0 m-0 nogutter">
          <app-franke-menu></app-franke-menu>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
