import { Pipe, PipeTransform } from '@angular/core';
import { BrandService } from '../services/brand.service';

@Pipe({
  name: 'frankeProductName'
})
export class FrankeMenuProductLabelPipe implements PipeTransform {

  mapHints = {
    // Bverage Families
    'Americano 6': 'Caffè Americano',
    'Cappuccino 6': 'Cappuccino',
    'Chococcino 6': 'Caffè Mocha',
    'Chocolate 6': 'Hot Chocolate',
    'Espresso 6': 'Espresso',
    'Double Espresso 6': 'Doppio Espresso',
    'Espresso Macchiato 5': 'Espresso Macchiato',
    'Espresso macchiato 6': 'Doppio Espresso Macchiato',
    'Tea water 6': 'Tea Hot Water',
    'Milk coffee 6': 'White Americano',
    'Latte macchiato 6': 'Latte Macchiato',
    'Latte macchiato 4': 'Caramel Macchiato',
    'Iced Latte macchiato 4': 'ICED Caramel Macchiato',
    'Caffè Latte 6': 'Caffè Latte',
    'Caffè Latte 5': 'Promo Latte',
    'Flat White 6': 'Flat White',
    'Iced Americano 5': 'ICED Americano',
    'Iced Caffè Latte 5': 'ICED Caffè Latte',
    'Iced Caffè Latte 6': 'Chilled Caffè Latte',
    'Iced Chococcino 5': 'ICED Caffè Mocha',
    'Custom Beverage 3': 'Chai Tea Latte',
    'Custom Beverage 4': 'ICED Chai Tea Latte',
    'Iced Latte Macchiato 5': 'ICED Latte Macchiato',
    'Iced Flat White 5': 'ICED Flat White',
    'Iced Cappuccino 5': 'ICED Cappuccino',
    'Iced Caffè Latte 4': 'ICED Promo Latte',
    'Iced Chocolate 5': 'ICED Chocolate'
  }

  constructor(private brandService: BrandService){}

  transform(value: any, ...args: any[]) {
    const brand = args.length > 0 ? args[0] : null;
    let result =  value;
    if( this.brandService.isStarbucksBranded(brand) ){
      const mapHints = this.mapHints;
      const code  = args.length > 1 ? args[1] : null;
      result =  code ? mapHints[code] ?? value : null;
    }
    return result;
  }

}
