import { Subject } from 'rxjs';
import { ProductService } from '../services/product.service';
import { CommandsService } from '../services/commands.service';
import { TouchlessStatus } from '../services/machine-status.service';

export abstract class AbstractPollResultStore {

  static numberOfIstances : number = 0;

  product: any;

  timer:any;

  pollIteration: number;
  POLL_FREQUENCY: number;
  MAX_POLL_ITERATIONS: number;

  busy = false;

  transaction_id: any;

  protected readonly change = new Subject<any>();
  public readonly change$ = this.change.asObservable();

  protected readonly result = new Subject<any>();
  public readonly result$ = this.result.asObservable();

  protected readonly error = new Subject<any>();
  public readonly error$ = this.error.asObservable();

  constructor(
    protected productService: ProductService,
    protected commandsService: CommandsService
  ) {
    AbstractPollResultStore.numberOfIstances++;
    console.log('Number of istances ' + AbstractPollResultStore.numberOfIstances );
    this.reset();
  }

  reset() {
    this.pollIteration = 0;
    this.POLL_FREQUENCY = 5000;
    this.MAX_POLL_ITERATIONS = 40;
  }

  public cancel() {
    this.stop();
    this.reset();
  }

  public abstract poll();

  public check(result: any, product: any) {
    this.pollIteration = 0;
    this.product = product;
    const data = result.data;

    if( ! this.transaction_id ){
      console.log(`ATTENZIONE transaction_id=${this.transaction_id}`);
    }
    this.transaction_id = data?.transaction_id ?? -1;

    if ( this.transaction_id < 0 ) {
      this.result.next({ transaction_status: data.transaction_status, goBack: false, product });
      return;
    }

    console.log(`setting polling timer to ${this.POLL_FREQUENCY }ms`);
    this.timer = setInterval( () => this.tick(), this.POLL_FREQUENCY );
  }

  async tick(){
    console.log(`Polling tick`);
    if( this.busy === true ){
      console.log(`Polling timer catch but previous response pending`)
      return;
    } else if (this.pollIteration >= this.MAX_POLL_ITERATIONS) {
      this.stop();
      this.result.next({ error: true, message: 'Cancelled, no response' });
    } else {
      console.log(`Poll for status`);
      this.poll();
    }
    this.pollIteration++;
  }

  processResult(response) {
    this.busy = false;
    const data = response.data;
    if ( data.transaction_status === TouchlessStatus.DISPENSING
      || data.transaction_status === TouchlessStatus.PAYMENT_IN_PROGRESS
      || data.transaction_status === TouchlessStatus.PAYMENT_ACCEPTED
      ) {
      this.change.next({...data});
      return;
    }
    this.stop();
    this.result.next({ transaction_status: data.transaction_status, goBack: true,  product: this.product });
  }

  processError(err){
    this.busy = false;
    if (this.pollIteration >= this.MAX_POLL_ITERATIONS) {
      this.stop();
      this.result.next({ error: true, message: 'Cancelled, no response' });
      return;
    }
  }

  stop(){
    if (this.timer) {
      console.log(`Stopping polling`);
      clearInterval(this.timer);
      console.log(`Stopped polling`);
    }
    this.busy = false;
  }

}
