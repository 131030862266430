<div class="modal-content">
  <!-- <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

  <div class="modal-body bg-popup-custom">
    <div class="container-fluid">

      <div class="text-center text-white">{{message}}</div>

      <div class="row justify-content-center mt-4">
        <div class="col-auto">
          <img class="spinner-logo" src="/assets/images/spinner.gif" alt="Waiting">
        </div>
      </div>

      <!-- <div class="row justify-content-center mt-4">
        <button class="btn btn-outline-light btn-no-focus" (click)="close()">
          CANCEL
        </button>
      </div> -->

    </div>
  </div>
</div>
