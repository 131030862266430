import { map } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { BrandService } from '../services/brand.service';

@Pipe({
  name: 'frankeLabel'
})
export class FrankeMenuLabelPipe implements PipeTransform {

  mapHints = {

    // Blend type
    Blanks: 'Espresso Roast',
    CustomBlend1: 'Espresso Roast',
    CustomBlend2: 'Blonde Roast',
    CustomBlend3: 'Decaf Roast',
    // cup Size
    Small:	'Espresso',
    Medium:	'Short',
    Large:	'Tall',
    Double:	'Grande',
    // milk type
    CustomMilk1: 'Americano Black',
    CustomMilk2: 'White Americano',
    //Flavor Type
    CustomFlavor1: 'SF-Vanilla',
    CustomFlavor2: 'SF-Caramel',
    CustomFlavor3: 'SF-Hazelnut',
    CustomFlavor4: 'Promo Flavor',
    //Temperatur Profile
    Warm:	'Regular',// Caffè Latte',
    Cold:	'Chilled',// Caffè Latte',
    //Cold:	'Iced Served Over'

    blendType: 'Bean choice',
    // CustomBlend1: 'Dark Roast',
    // CustomBlend2: 'Blonde Roast',

    flavorType: 'Add flavor',
    FlavorTypeNo: 'No flavor added',

    milkType: 'Milk type selection',
    extraShotEspresso: 'Extra shot',
    // extraShotEspresso: 'Extra expresso choice',
    ExtraShotEspressoYes: 'Yes',
    ExtraShotEspressoNo: 'No',

    temperatureProfile: 'Temperature',
  }

  mapHintsAlternate: any;

    constructor(
      private brandService: BrandService
    ){}


  createMapHintsAlternate(){
    if( this.mapHintsAlternate )
      return;
    const map = {...this.mapHints};
    map.CustomMilk1  = 'Dairy';
    map.CustomMilk2  = 'Plant-based';

    map.CustomBlend1 = 'Superiore';
    map.CustomBlend2 = 'Espresso';

    map['CaneSugar'] = 'Sugar cane';

    map.FlavorTypeNo = 'No syrup';
    map['no flavor'] = 'No syrup';

    this.mapHintsAlternate = map;
    // TODO MODIFY
  }

  getMapHints(brand:string){
    if( this.brandService.isStarbucksBranded(brand) ){
      //console.log('USE DEFAULT MAP HINT');
      return this.mapHints;
    }
    //console.log('USE ALTERNATE MAP HINT');
    this.createMapHintsAlternate();
    return this.mapHintsAlternate;
  }

  transform(value: any, ...args: any[]) {
    const brand = args[0];
    const mapHints = this.getMapHints(brand);
    const result =  value ? mapHints[value] ?? value : null;
    return result;
  }

}
