import { Observable } from "rxjs";

export class Machine {
  machine_id: string;
  vendor: number;
  brand?: string;
  data: any[];
}

export class ProductChoice {
  beverageFamily?: MenuItem;
  cupSize?: MenuItem;

  blendType?: MenuItem;
  extraShotEspresso?: MenuItem;
  //extraShotFlavor?: MenuItem;
  milkType?: MenuItem;
  flavorType?: MenuItem;
  temperatureProfile?: MenuItem;

  product_id: string;
}

export class MenuItem {
  type: string;
  code: string;
  label: string;
  picture: string;

  price?:number;
  currency?:string;
  position?: number;

  available?: boolean;

  submenu?: Menu;
}

export class Menu{
  back?: string;
  code: string;
  options: MenuItem[];
}

export interface IProductStore {
  brand?: string;

  paymentSessionData$: Observable<any>;
  currentChoice$: Observable<any>;
  currentMenu$: Observable<Menu>;
  loading$: Observable<boolean>;
  result$: Observable<any>;
  error$: Observable<any>;
  statusChange$: Observable<any>;

  getCurrentChoice():any;
  select(option: any);
  gotoMenu(back: any);
  startErogation();
  startPayment();
  startPolling();
  stopPolling();
}
