export interface Product {
  id: number;
  name: string;
  picture: string;
  type: string;
  price: number;
  currency: string;
  position: number;
  available: boolean;
  children: any[];
}

export interface CommandMenuResponse{
  machine_id: string;
  vendor: number;
  brand: string;
  data: Product[];
}

export interface ISelection {
  selection: number;
  label: string;
  price: number;
  pricelist: null;
  pricelist_type: null;
  payment_device_type: null;
}

export enum IVendor {
  Vendon = 0,
  Franke = 1,
}
