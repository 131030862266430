import { BrandService } from 'src/app/services/brand.service';
import { AbstractAppComponent } from 'src/app/components/AbstractComponent.component';
import { MachineStatusService } from './../../../services/machine-status.service';
import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IProductStore, Menu, ProductChoice } from 'src/app/models/machine.model';
import { SwalService } from 'src/app/services/swal-service.service';
import { ProducStoreFactory } from 'src/app/stores/products.store.factory';
import { ProgressBarModalComponent } from 'src/app/components/progress-bar-modal/progress-bar-modal.component';
import { appRoutesNames } from 'src/app/app-routing.names';
import { Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Component({
  selector: 'app-choice-container',
  styleUrls: ['./franke-menu.component.css'],
  template: `

  <div class="d-flex align-items-center justify-content-start no-gutter opt-buttons-container pl-4 pr-4 pb-4" #panel [class.starbucks]="store.brand">
    <ng-container *ngFor="let option of menu.options;let last=last;let index=index">
      <div class="flex-fill d-flex align-items-center justify-content-start no-gutter p-1 opt-button-ext" #optbutton
           [class.selected]="option.code==choice[menu.code]?.code">
        <button (click)="select(option)"
              class="d-flex align-items-center justify-content-start no-gutter w-100 p-1 opt-button"
              [class.selected]="option.code==choice[menu.code]?.code"
              [class.disabled]="option.available === false"
              [disabled]="option.available === false">
            <img class="opt-icon" [src]="option.code | frankeIcon : store.brand : menu.code" [alt]="option.code | frankeLabel : store.brand">
            <div class="opt-label">{{option.code | frankeLabel : store.brand}}</div>
          </button>
      </div>
      <div class="opt-button-spacer d-sm-none" *ngIf="last && index > 1"></div>
    </ng-container>
  </div>
  `
})
export class ChoiceContainerComponent implements AfterViewInit {

  @Input()
  store: IProductStore;

  @Input()
  choice: ProductChoice;

  @Input()
  menu: Menu;

  @ViewChild('panel') panel: ElementRef;

  @ViewChildren('optbutton') options : QueryList<ElementRef>;

  calculate(s){
    let l = s;
    for (const o of this.options) {
      if( o.nativeElement.classList.contains('selected') ){
        return l;
      }
      l += o.nativeElement.offsetWidth;
    }
    return l;
  }

  ngAfterViewInit(): void {
    if( this.panel )
      this.panel.nativeElement.scrollLeft+=this.calculate(0);
  }

  select(option){
    this.store.select(option);
  }
}

@Component({
  selector: 'app-franke-menu',
  templateUrl: './franke-menu.component.html',
  styleUrls: ['./franke-menu.component.css']
})
export class FrankeMenuComponent extends AbstractAppComponent{

  public store: IProductStore;
  public mode : 'normal'|'breadcrumbs'|'buttons' = 'buttons';

  activeModal:any;

  constructor(
    public factory: ProducStoreFactory,
    public brandService: BrandService,
    private spinnerService: SpinnerVisibilityService,
    private modalService: NgbModal,
    private swalService: SwalService,
    private router: Router,
    private machineStatusService: MachineStatusService
  ) {
    super();
    this.register(
      factory.currentStore$.subscribe(
        store => {
          this.store = store;

          this.register(
            this.store.result$.subscribe(
              r => {
                console.log( `Store ${this.store?.constructor?.name} as returned a result`, r)
                if( r )
                  this.showResult(r);
              }
            )
          );

          this.register(
            this.store.error$.subscribe(
              err => {
                console.log( 'Store as returned a error', err)
                if( err )
                  this.showError(err);
              }
            )
          );

        }
      )
    );
  }

  select(option){
    this.store.select(option);
  }

  gotoMenu(code:string){
    this.store.gotoMenu(code);
  }

  startBrew(product_id:string){
    (async () => {
      this.activeModal = this.modalService.open(ProgressBarModalComponent, {
        keyboard: false,
        backdrop: 'static',
        centered: true
      });
      console.log(`Start brew of ${product_id}`);
      this.store.startErogation();
      const result = await this.activeModal.result;
      if( result?.reason === 'cancelled' ){
        this.store.stopPolling();
      }
    })();
  }

  cancel(){
    this.store.gotoMenu('beverageFamily');
  }

  showResult( result:any ) {
    console.log(`Show brew results`,result);
    const mapping = this.machineStatusService.getMessageFromStatus(result.transaction_status);

    if (mapping.successful === true){
      this.swalService.showConfirm("Success", mapping.message).then(
        (r) => { if(r) this.navigateSuccessPage() }
      )
    }
    else if (mapping.successful === false){
      this.swalService.showError('Error', mapping.message).then(
        (r) => { if(r) this.navigateFailurePage() }
      )
    }
    else{
      this.swalService.showInfo("Message received", mapping.message).then(
        (r) => { if(r) this.store.stopPolling() }
      )
    }
  }

  showError( error:any ) {
   console.log(`Show brew error`,error);
   const message = error?.error?.message ? error?.error?.message : error?.status ? `${error?.status}` : 'Unknown error occurred.';
   //error.statusText ? `${error.status} - ${error.statusText}` : error.message;
   setTimeout( async () => {
      this.hideModal();
      await this.swalService.showError('Error', message);
      this.navigateFailurePage();
    },50);
  }

  hideModal(){
    this.modalService.dismissAll();
    this.spinnerService.hide();
  }

  navigateSuccessPage(){
    this.hideModal();
    setTimeout( async () => {
      this.router.navigate([`${appRoutesNames.success}`])}
    ,50);
  }

  navigateFailurePage(){
    this.hideModal();
    setTimeout( async () => {
      this.router.navigate([`${appRoutesNames.failure}`])}
    ,50);
  }
}
