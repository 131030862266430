import { Component } from '@angular/core';
import { ProducStoreFactory } from 'src/app/stores/products.store.factory';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent {

  constructor( public factory: ProducStoreFactory ) {}

}
