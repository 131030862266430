import { CheckoutComponent } from './../../checkout/checkout.component';
import { AbstractAppComponent } from 'src/app/components/AbstractComponent.component';
import { Component, NgZone} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IProductStore } from 'src/app/models/machine.model';
import { SwalService } from 'src/app/services/swal-service.service';
import { ProducStoreFactory } from 'src/app/stores/products.store.factory';
import { ProgressBarModalComponent } from 'src/app/components/progress-bar-modal/progress-bar-modal.component';
import { appRoutesNames } from 'src/app/app-routing.names';
import { Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { AdyenCheckoutStore } from 'src/app/stores/checkout-adyen-store';
import { MachineStatusService } from 'src/app/services/machine-status.service';

@Component({
  selector: 'app-vendon-new-menu',
  templateUrl: './vendon-menu.component.html',
  styleUrls: ['./vendon-menu.component.css']
})
export class VendonMenuComponent extends AbstractAppComponent {

  public store: IProductStore;

  activeModal:any;

  constructor(
    public factory: ProducStoreFactory,
    checkoutStore: AdyenCheckoutStore,
    private spinnerService: SpinnerVisibilityService,
    private modalService: NgbModal,
    private swalService: SwalService,
    private router: Router,
    private machineStatusService: MachineStatusService,
    private zone: NgZone
  ) {
    super();
    this.register(
        factory.currentStore$.subscribe(
          store => {
            this.store = store;

            this.register(
              this.store.result$.subscribe(
                r => {
                  console.log( `Store ${this.store?.constructor?.name} as returned a result`, r)
                  if( r )
                    this.showResult(r);
                }
              )
            );

            this.register(
              this.store.error$.subscribe(
                err => {
                  console.log( 'Store as returned a error', err)
                  if( err )
                    this.showError(err);
                }
              )
            );
          }
        )
      );

    this.register(
      checkoutStore.complete$.subscribe(
        r => {
          console.log('CHECKOUT COMPLETE',r);
          this.startPolling(this.store.getCurrentChoice(),false);
        }
      )
    );

    this.register(
      checkoutStore.error$.subscribe(
        r => {
          console.log('CHECKOUT ERROR',r);
          this.showError({message:'Payment failed.'});
        }
      )
    );
  }

  select(option){
    this.store.select(option);
  }

  async startBrew(choice){
    if( choice?.price > 0 ){
      this.startPayment(choice);
    } else {
      this.startFreeBrew(choice);
    }
  }

  startPayment(choice){
    console.log(`Start payment process for product ${choice.code}`,choice);
    this.register(
      this.store.paymentSessionData$.subscribe(
        data => {
          console.log( 'Payment session created', data );
          const transaction_id = (data as any )?.data?.psp_response?.metadata?.transaction_id;
          console.log(`Current transaction_id is: ${transaction_id}`);
          // this.showCheckout = true;
          this.startCheckout(data);
        },
      )
    );
    this.store.startPayment();
  }

  startCheckout(data){
    this.hideModal()
    this.zone.run(async()=>{
      this.activeModal = this.modalService.open(CheckoutComponent, {
        keyboard: false, backdrop: 'static', centered: true
      });
      this.activeModal.componentInstance.data = data;
    });
  }

  startFreeBrew(choice){
    console.log(`Start erogation process for free product ${choice.code}`,choice);
    this.startPolling(choice,true);
  }

  startPolling(choice,startErogation?,timeout?){
    this.hideModal()
    this.zone.run(async()=>{
      this.activeModal = this.modalService.open(ProgressBarModalComponent, {
        keyboard: false, backdrop: 'static', centered: true
      });
      if( startErogation ) {
        console.log(`Start brew of ${choice.code}`);
        this.store.startErogation();
      } else {
        console.log(`Start polling of ${choice.code}`);
        this.store.startPolling();
      }
      const result = await this.activeModal.result;
      if( result?.reason === 'cancelled' ){
        this.store.stopPolling();
      }
    });
  }

  cancel(){
    this.store.gotoMenu('beverageFamily');
  }

  showResult( result:any ) {
    console.log(`Show brew results`,result);
    const mapping = this.machineStatusService.getMessageFromStatus(result.transaction_status);
    if( ! mapping ){
      this.swalService.showError("Message received", result.message).then(
        (r) => { if(r) this.store.stopPolling() }
      )
    }
    else if (mapping.successful === true){
      this.swalService.showConfirm("Success", mapping.message).then(
        (r) => { if(r) this.navigateSuccessPage() }
      )
    }
    else if (mapping.successful === false){
      this.swalService.showError('Error', mapping.message).then(
        (r) => { if(r) this.navigateFailurePage() }
      )
    }
    else {
      this.swalService.showInfo("Message received", mapping.message).then(
        (r) => { if(r) this.store.stopPolling() }
      )
    }
  }

  showError( error:any ) {
    console.log(`Show brew error`,error);
   const message = error?.error?.message ? error?.error?.message : error?.status ? `${error?.status}` : 'Unknown error occurred.';
   //error.statusText ? `${error.status} - ${error.statusText}` : error.message;
    this.hideModal();
    try{
      this.zone.runGuarded( async() => {
        await this.swalService.showError('Error', message);
      } );
    }catch(err){console.error(err)}
    this.navigateFailurePage();
   }

   hideModal(){
    this.zone.run(()=>{
      try{ this.modalService.dismissAll(); }
      catch(err){console.error('Error dismitting all modals',err);}
      try{ this.spinnerService.hide(); }
      catch(err){console.error('Error hiding spinner',err);}
    });
   }

   navigateSuccessPage(){
    this.zone.run(()=>{
      this.hideModal();
      try{ this.router.navigate([`${appRoutesNames.success}`]); }
      catch(err){console.error('Error navigating to success page',err);}
    });
  }

  navigateFailurePage(){
    this.zone.run(()=>{
      this.hideModal();
      try{ this.router.navigate([`${appRoutesNames.failure}`]); }
      catch(err){console.error('Error navigating to failure page',err);}
    });
  }
}
