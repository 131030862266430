import { Transaction } from './../../../../../backend_np/app/models/transaction';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MachineStatusService } from 'src/app/services/machine-status.service';
import { ProducStoreFactory } from 'src/app/stores/products.store.factory';
import { AbstractAppComponent } from '../AbstractComponent.component';

@Component({
  selector: 'app-progress-bar-modal',
  templateUrl: './progress-bar-modal.component.html',
  styleUrls: ['./progress-bar-modal.component.css']
})
export class ProgressBarModalComponent extends AbstractAppComponent implements OnInit {

  message: string;

  constructor(
    private factory: ProducStoreFactory,
    private modal: NgbActiveModal,
    private machineStatusService: MachineStatusService
    ) {
      super();
  }

  ngOnInit() {
    console.log("subscribe created store" );
    this.register(
      this.factory.currentStore$.subscribe(
        store => this.createdStore(store)
      )
    );
    this.message = 'Processing request...';
  }


  createdStore(store){
    this.clearSubscriptions();
    console.log("subscribe status change" );
    this.register(
      store.statusChange$.subscribe(
        result => {
          let message:string = '';
          const transaction_status = result.transaction_status;
          const refund_status = result.refund_status;
          console.log(`Received status transaction_status:${transaction_status}, refund_status:${refund_status}`);
          if( refund_status ) {
            message = this.machineStatusService.getMessageFromRefundStatus(refund_status).message;
            console.log(`Message from refund_status:${refund_status} is ${message}`);
          } else {
            message = this.machineStatusService.getMessageFromStatus(transaction_status).message;
            console.log(`Message from transaction_status:${transaction_status} is ${message}`);
          }
          this.message = message;
        }
      )
    );
  }

  close(){
    this.modal.close({reason: 'cancelled'});
  }
}
