import { Injectable } from '@angular/core';
import { IVendor, Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  product: Product;
  vendor: IVendor;
  machine_id: any;
  token: string;

  constructor() { }
}
