<ng-container *ngIf="store">
  <div>
    <ng-container
      *ngTemplateOutlet="loadedMenu; context:{store, menu:store.currentMenu$ | async,choice:store.currentChoice$ | async}">
    </ng-container>
  </div>
</ng-container>

<ng-template #loadedMenu let-store="store" let-menu="menu" let-choice="choice">
  <ng-container *ngIf="choice">
    <ng-container *ngTemplateOutlet="recapMenu; context:{store,menu,choice}"></ng-container>
  </ng-container>
  <ng-container *ngIf="!choice">
    <ng-container *ngTemplateOutlet="beverageFamilyMenu; context:{store,menu}"></ng-container>
  </ng-container>
</ng-template>


<ng-template #beverageFamilyMenu let-store="store" let-menu="menu">
  <div class="selection-menu">
    <div class="row justify-content-center">

      <div class="col-auto" *ngFor="let option of menu.options">

        <div class="product-container" (click)="select(option)">
          <img class="product-logo" [src]="option.picture | productLogo" [alt]="option.label">
          <div class="product-name" [title]="option.name">{{option.label}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #recapMenu let-store="store" let-menu="menu" let-choice="choice">
  <!-- <div class="d-flex flex-column align-items-start justify-content-start no-gutter recap-menu mt-4">
    <ng-container *ngFor="let option of menu.options;let odd = odd; let even = even; let index = index; let last = last">
      <ng-container *ngIf="option?.submenu?.options?.length > 0">
        <ng-container *ngTemplateOutlet="optionsMenu; context:{store,menu:option.submenu,choice}"></ng-container>
      </ng-container>
    </ng-container>
  </div> -->
  <div class="recap-menu">
    <div class="single-text-row product-name">{{choice.label}}</div>

    <div class="row justify-content-center">
      <div class="col-auto">
        <img class="product-logo" [src]="choice.picture | productLogo" alt="product.name">
      </div>
    </div>

    <div class="single-text-row font-weight-bold">Price</div>
    <div class="single-text-row font-italic">
      {{(choice?.price??0) | number : '1.2-2'}} {{choice?.currency}}
    </div>

    <div class="buttons-container">
      <div class="single-text-row">Make sure you already placed your cup</div>
      <div class="d-flex justify-content-center mt-3">
        <button class="btn btn-light btn-product mr-1" (click)="startBrew(choice)">
          START
        </button>
        <button class="btn btn-outline-light btn-product ml-1" (click)="cancel()">
          CANCEL
        </button>

      </div>
    </div>
  </div>

</ng-template>

<!-- <pre class="text-light">
  {{store.currentMenu$ | async | json}}
</pre>

<pre class="text-light">
  {{store.currentChoice$ | async | json}}
</pre> -->

<!-- <app-checkout *ngIf="showCheckout" [data]="checkoutData"></app-checkout> -->
