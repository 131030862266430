import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {

  COMMANDS_PATH = '/commands';

  constructor(private apiService: ApiService) { }

  getMenu(token: string) {
    const body = { command: 'menu', token };
    return this.apiService.post(this.COMMANDS_PATH, body);
  }

  startFrankeDispensing(product_id: string, token: string) {
    const body = { command: 'brew', product_id, token };
    return this.apiService.post(this.COMMANDS_PATH, body);
  }

  startVendonDispensing(credit: number, selection_id: number, token: string) {
    const body = { command: 'brew', credit, selection_id, token };
    return this.apiService.post(this.COMMANDS_PATH, body);
  }

  startVendonPayment(credit: number, currency: string, return_url:string, selection_id: number, token: string) {
    const body = { command: 'create-payment-session', credit, currency, return_url, selection_id, token };
    return this.apiService.post(this.COMMANDS_PATH, body);
  }

  getVendonStatus(transaction_id: string, token: string) {
    return this.apiService.get(`${this.COMMANDS_PATH}?transaction_id=${transaction_id}&token=${token}`);
  }

  getFrankeStatus(transaction_id: string, token: string) {
    return this.apiService.get(`${this.COMMANDS_PATH}?transaction_id=${transaction_id}&token=${token}`);
  }
}
