import { BehaviorSubject, Subject, of, Observable } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { CommandsService } from 'src/app/services/commands.service';
import { IProductStore, Machine, Menu, MenuItem } from 'src/app/models/machine.model';
import { VendonPollResultStore } from './poll-result-vendon.store';
import { MachineStatusService, TouchlessStatus } from '../services/machine-status.service';
import { environment } from 'src/environments/environment';

export class VendonProductStore implements IProductStore {

  machine_id: string;
  brand: string;
  vendor: number;
  data: any[];

  private readonly currentChoice = new BehaviorSubject<MenuItem>(null);
  public readonly currentChoice$ = this.currentChoice.asObservable();

  private readonly currentMenu = new BehaviorSubject<Menu>(null);
  public readonly currentMenu$ = this.currentMenu.asObservable();

  private readonly loading = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this.loading.asObservable();

  private readonly result = new Subject<any>();
  public readonly result$ = this.result.asObservable();

  private readonly error = new Subject<any>();
  public readonly error$ = this.error.asObservable();

  private readonly expiredToken = new BehaviorSubject<boolean>(false);
  public readonly expiredToken$ = this.expiredToken.asObservable();

  private readonly paymentSessionData = new Subject<any>();
  public readonly paymentSessionData$ = this.paymentSessionData.asObservable();

  public statusChange$: Observable<any>;

  pollStore: VendonPollResultStore;

  private static instance: VendonProductStore;

  public static getInstance(
    productService: ProductService,
    commandsService: CommandsService,
    machine: Machine
  ):VendonProductStore{
      if( ! VendonProductStore.instance )
      VendonProductStore.instance = new VendonProductStore(productService,commandsService,machine);
      return VendonProductStore.instance;
  }

  private constructor(
    private productService: ProductService,
    private commandsService: CommandsService,
    private machine: Machine
  ) {
    this.pollStore = new VendonPollResultStore(productService,commandsService);
    this.statusChange$ = this.pollStore.change$;

    this.machine_id = machine.machine_id;
    this.vendor = machine.vendor;
    this.brand = machine.brand;
    this.data = machine.data;

    this.pollStore.result$.subscribe(
      r => {
        this.result.next(r);
        if( true )
          this.expiredToken.next( true );
      }
    );

    this.buildProductsMenu();
  }

  public select(option:MenuItem){
    const choice = {...option};
    this.notifyChoice(choice);
  }

  public startErogation(){
    this.createdPaymentSessionData = null;
    const choice = this.currentChoice.getValue();
    const position = choice?.position;

    if( choice.price <= 0 ){
      // siamo sicuri che serva sto 1 ? non dovrebbe andare bene 0 ?
      let credit = 1;
      console.log(`Start free erogation of product in position ${position}, credit: ${credit}`,choice);
      const sub = this.commandsService.startVendonDispensing(credit, position,  this.productService.token).subscribe(
        result => this.checkResult(result),
        error => {
          console.log(`Error in start free erogation of ${position}, credit: ${credit}`,error?.error);
          this.error.next(error);
        },
        () => sub.unsubscribe()
      )
    }
  }

  createdPaymentSessionData: any;

  public startPayment(){
    this.createdPaymentSessionData = null;
    const choice = this.currentChoice.getValue();
    const position = choice?.position;

    console.log(`Start payment session creation for product in position ${position}`,choice);

    const sub =
    this.commandsService.startVendonPayment(choice.price, choice.currency, environment.selfUrl, position, this.productService.token).subscribe(
      result => {
        console.log(`Notifying payment session creation`,result);
        this.createdPaymentSessionData = result;
        this.paymentSessionData.next(result);
      },
      error => {
        console.log(`Error in start payment of ${position}, credit: ${choice.price}`,error?.error);
        //TODO notificare l'errore
        this.error.next(error);
      },
      ()=>sub.unsubscribe()
    )
  }

  private checkResult(result) {
    console.log(`Check result`, result);
    if( ! result && this.createdPaymentSessionData ){
      console.log( 'Creating poll message from last payment session info' );
      console.log(  this.createdPaymentSessionData );
      const transaction_id = this.createdPaymentSessionData?.data?.psp_response?.metadata?.transaction_id;
      console.log( `Creating poll message from last payment session info transaction_id = ${transaction_id}` );
      const transaction_status = TouchlessStatus.PAYMENT_IN_PROGRESS;
      result = { data: { transaction_id, transaction_status} };
      console.log( 'Created poll message from last payment session info' )
      console.log(result);
    }
    const position = this.currentChoice.getValue()?.position;
    const product = this.data.find( p => p.position === position );
    this.pollStore.check(result,product);
  }

  gotoMenu(back: any) {
    this.notifyChoice( null );
  }

  buildProductsMenu(){
    const menu = { code:'products', options:[] }
    menu.options = this.machine.data.map( p => this.createOption( p ) );
    this.currentMenu.next(menu);
  }

  notifyChoice(choice){
    this.currentChoice.next( choice );
  }

  createOption(product: any) : MenuItem{
    return { code:product.id, type:product.type, label:product.name, picture:product.picture,
             position:product.position, price:product.price, currency:product.currency };
  }

  startPolling() {
    console.log('DO START POLLING');
    this.checkResult(null);
  }

  stopPolling(){
    console.log('DO STOP POLLING');
    this.pollStore.cancel();
  }

  getCurrentChoice() {
    return this.currentChoice.getValue();
  }

}
