import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(){
    console.log(`Starting app ,version:${environment.version}`);
    console.log(`Api root url is:${environment.apiRootUrl}`);
  }
}
