import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import { ProducStoreFactory } from 'src/app/stores/products.store.factory';
import { AbstractAppComponent } from 'src/app/components/AbstractComponent.component';

@Component({
  templateUrl: './failure.component.html',
  styleUrls: [ './failure.component.css']
})
export class FailureComponent extends AbstractAppComponent {

  error$: Observable<any>;

  constructor(
    private factory: ProducStoreFactory
  ) {
    super();
    this.register(
      this.factory.currentStore$.subscribe(
        store => {
          if( store ){
            this.error$ = store.result$;
            this.clearSubscriptions();
          }
        }
      )
    );
  }
}
