import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import AdyenCheckout from '@adyen/adyen-web';
import { AdyenCheckoutStore } from 'src/app/stores/checkout-adyen-store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls:['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, AfterViewInit {

  @Input()
  data: any;

  @ViewChild('hook', { static: true }) hook: ElementRef;

  removeDropInSub:any;

  dropinMounted: DropinElement;

  adyenCheckout: DropinElement;

  constructor(
    private modal: NgbActiveModal,
    private store: AdyenCheckoutStore,
    private zone: NgZone ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.zone.runGuarded(
      (async () => {
        console.log(`Configuring checkout process with data`, this.data);
        const configuration = this.store.startCheckoutProcess(this.data?.data);
        console.log(`Using Configuration to instantiate adyen checkout`, configuration);
        const checkout = await AdyenCheckout(configuration);
        // Create an instance of Drop-in and mount it to the container you created.
        console.log(`Creating dropin using checkout`, checkout);
        // this.adyenCheckout = checkout.create('dropin');
        console.log(`Mounting checkout to element`);
        this.dropinMounted = await checkout.create('dropin').mount(this.hook.nativeElement);
        // this.dropinMounted = this.adyenCheckout.mount('#dropin-container');
        console.log(`Dropin mounted is`, this.dropinMounted);
        this.removeDropInSub = this.store.complete$.subscribe(e=>this.removeDropinComponent(e));
      })
    );
  }

  removeDropinComponent(e){
    console.log(`Payment completed try to remove dropin component after 2 seconds`,e);
    this.zone.run(
      async () => {
        setTimeout(() => {
        // try{
        //   this.zone.runOutsideAngular(()=>{
        //     document.getElementById('payment-page').remove();
        //   })
        //   this.removeDropInSub?.unsubscribe();
        // } catch (err){
        //   console.error('removeDropinComponent',err)
        // }
        this.close("payment_completed");
      },2000);
    });
  }

  close(reason:string){
    this.zone.run(
      () => {  this.modal.close({reason}); }
    );
  }

}
