import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { appRoutesNames } from './app-routing.names';

import { HomeComponent } from './pages/home/home.component';
import { PageComponent } from './pages/page/page.component';
import { SuccessComponent } from './pages/success/success.component';
import { FailureComponent } from './pages/failure/failure.component';

const childRoutes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: appRoutesNames.home
  },
  {
    path: appRoutesNames.home,
    component: HomeComponent
  },
  {
    path: appRoutesNames.success,
    component: SuccessComponent
  },
  {
    path: appRoutesNames.failure,
    component: FailureComponent
  }
]

const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    children: childRoutes
  },
  {
    path: '**',
    redirectTo: appRoutesNames.home,
    pathMatch: 'full'
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
