import { FrankeMenuIconPipe } from './pipes/franke-menu-icon';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { PageComponent } from './pages/page/page.component';
import { ErrorInterceptorProvider } from './interceptors/error.interceptor';
import { ProductLogoPipe } from './pipes/product-logo.pipe';
import { ProgressBarModalComponent } from './components/progress-bar-modal/progress-bar-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SuccessComponent } from './pages/success/success.component';
import { FrankeMenuLabelPipe } from './pipes/franke-menu-label';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FailureComponent } from './pages/failure/failure.component';
import { ChoiceContainerComponent, FrankeMenuComponent } from './components/devices/franke-menu/franke-menu.component';
import { VendonMenuComponent } from './components/devices/vendon-menu/vendon-menu.component';

import { MockupInterceptorProvider } from './interceptors/mockup.interceptor';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { FrankeMenuProductLabelPipe } from './pipes/franke-menu-product-label';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    PageComponent,
    ProductLogoPipe,
    ProgressBarModalComponent,

    FrankeMenuIconPipe,
    FrankeMenuLabelPipe,
    FrankeMenuProductLabelPipe,
    FrankeMenuComponent,
    ChoiceContainerComponent,

    VendonMenuComponent,

    SuccessComponent,
    FailureComponent,
    CheckoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    SweetAlert2Module.forRoot({ provideSwal }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule
  ],
  providers: [
    MockupInterceptorProvider,
    ErrorInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export async function provideSwal() {
  return Swal.mixin({
    position: 'center',
    padding: '1em',
    buttonsStyling: false,
    allowOutsideClick: false,
    customClass: {
      popup: 'animated fadeIn fast bg-popup-custom',
      icon: 'bg-header-margin', //TODO REMOVED AFTER MIGRATION
      actions: 'z-index-0',
      confirmButton: 'btn btn-outline-light btn-no-focus',
      cancelButton: 'btn btn-outline-light btn-no-focus',
      title: 'font-size-22 font-weight-bold text-white',
      htmlContainer: 'font-size-16 text-white bg-header-margin',
    },
    confirmButtonText: 'CONFIRM',
    cancelButtonText: 'CANCEL',
    //animation: false, //TODO REMOVED AFTER MIGRATION
  });
}
