import { IVendor } from 'src/app/models/product.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ProductService } from '../services/product.service';
import { CommandsService } from '../services/commands.service';
import { IProductStore } from '../models/machine.model';
import { FrankeProductStore } from './products-franke.store';
import { VendonProductStore } from './products-vendon.store';

@Injectable({ providedIn: 'root' })
export class ProducStoreFactory {
  private readonly currentStore = new BehaviorSubject<IProductStore>(null);
  public readonly currentStore$ = this.currentStore.asObservable();

  private readonly loading = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this.loading.asObservable();

  constructor(
    private productService: ProductService,
    private commandsService: CommandsService
  ) {}

  public init(token: string) {
    this.productService.token = token;
    this.loading.next(true);
    this.commandsService
      .getMenu(token)
      .pipe(
        map((r) => r),
        catchError((err) => {
          console.error(`Unable to load menu`, err);
          return of(null);
        }),
        tap((r) => {
          if (!r) return of(null);
          const machine = {
            machine_id: r.machine_id,
            vendor: r.vendor,
            brand: r.brand,
            data: r.data,
          };

          if (machine.vendor === IVendor.Franke) {
            this.currentStore.next(
              FrankeProductStore.getInstance(
                this.productService,
                this.commandsService,
                machine
              )
            );
          } else {
            this.currentStore.next(
              VendonProductStore.getInstance(
                this.productService,
                this.commandsService,
                machine
              )
            );
          }
        }),
        catchError((err) => {
          console.error(`Unable to process menu`, err);
          return of(null);
        }),
        finalize(() => {
          this.loading.next(false);
        })
      )
      .subscribe();
  }
}
