<ng-container *ngIf="store">
  <div class="color-scheme-1" [class.starbucks]="store.brand">
    <ng-container *ngTemplateOutlet="currentChoice; context:{store, menu:store.currentMenu$ | async, choice:store.currentChoice$ | async}"></ng-container>
    <ng-container *ngTemplateOutlet="loadedMenu; context:{store, menu:store.currentMenu$ | async,choice:store.currentChoice$ | async}"></ng-container>
  </div>
</ng-container>

<ng-template #currentChoice let-store="store" let-choice="choice" let-menu="menu">
  <div class="d-flex flex-column justify-content-center align-items-center color-scheme-1 position-sticky sticky-top" [class.starbucks]="store.brand">
    <ng-container [ngSwitch]="menu.code">
      <ng-container *ngSwitchCase="'beverageFamily'">
        <div>
          Welcome
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'cupSize'">
        <div>
          Customize your
        </div>
        <div class="text-medium text-center " >
          {{choice?.beverageFamily?.label | frankeProductName : store.brand : choice?.beverageFamily?.code }}
        </div>
        <img *ngIf="menu.code !== 'cupSize' " [src]="choice?.beverageFamily?.picture" class="img-200 p2"/>
      </ng-container>
      <ng-container *ngSwitchCase="'recap'">
        <div>
          Customize your beverage
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #loadedMenu let-store="store" let-menu="menu" let-choice="choice">
  <ng-container *ngIf="menu">
    <ng-container [ngSwitch]="menu.code">
      <ng-container *ngSwitchCase="'cupSize'">
        <ng-container *ngTemplateOutlet="cupSizeMenu; context:{store,menu,choice}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'recap'">
        <ng-container *ngTemplateOutlet="recapMenu; context:{store,menu,choice}"></ng-container>
        <ng-container *ngTemplateOutlet="actionButtons; context:{choice}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="beverageFamilyMenu; context:{store,menu}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>


<ng-template #beverageFamilyMenu let-store="store" let-menu="menu">
  <div class="d-flex flex-wrap justify-content-center">
    <button class="btn btn-bf d-flex align-items-start justify-content-around"
        *ngFor="let option of menu.options" (click)="select(option)" [disabled]="option.available===false" [class.disabled]="option.available === false">
        <div class="d-flex flex-column justify-content-start align-items-center">
          <div class="product-container">
            <img class="product-logo" [src]="option.picture" [alt]="option.label | frankeProductName : store.brand : option.code">
          </div>
          <div class="product-name">{{option.label | frankeProductName : store.brand : option.code}}</div>
      </div>
    </button>
  </div>
</ng-template>

<ng-template #cupSizeMenu let-store="store" let-menu="menu" let-choice="choice">
  <div class="d-flex justify-content-center align-items-center w-100 mt-4">
    <div *ngFor="let option of menu.options" (click)="select(option)" [class.disabled]="option.available === false"
         class="d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex justify-content-center align-items-center w-50">
        <img [src]="option.code | frankeIcon : store.brand : 'cupSize'" [alt]="option.label" class="cupsize-img"
             [title]="option.code" [alt]="option.code | frankeLabel : store.brand">
      </div>
      <div class=" choice-label m0" [class.selected]="option.code==choice?.cupSize?.code">{{option.code | frankeLabel : store.brand}}</div>
    </div>
  </div>
</ng-template>

<ng-template #recapMenu let-store="store" let-menu="menu" let-choice="choice">
  <div class="d-flex flex-column align-items-start justify-content-start no-gutter recap-menu mt-4">
    <ng-container *ngFor="let option of menu.options;let odd = odd; let even = even; let index = index; let last = last">
      <ng-container *ngIf="option?.submenu?.options?.length > 0">
        <ng-container *ngTemplateOutlet="optionsMenu; context:{store,menu:option.submenu,choice}"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #optionsMenu let-store="store" let-menu="menu" let-choice="choice">
  <ng-container *ngIf="!(! brandService.isStarbucksBranded(store.brand) && menu.code==='temperatureProfile')">
    <div class="d-flex align-items-center justify-content-start no-gutter">
      <img [src]="menu.code | frankeIcon : store.brand : 'buttons'" [alt]="menu.code | frankeLabel : store.brand" class="recap-menu-icon mr-3">
      <div class="recap-menu-label">{{menu.code | frankeLabel : store.brand}}</div>
    </div>
    <app-choice-container [store]="store" [menu]="menu" [choice]="choice"></app-choice-container>
  </ng-container>
</ng-template>

<ng-template #actionButtons let-choice="choice">
  <div class="pre-footer-spacer"></div>
  <div class="d-flex align-items-center justify-content-center fixed-bottom footer color-scheme-1 inverted">
    <img [src]="choice?.beverageFamily?.picture" class="img-150 ml-2 mt-2"/>
    <div class="p-3 flex-grow-1">
      <div class="text-small text-left text-uppercase" *ngIf="choice?.cupSize && brandService.isStarbucksBranded(store.brand)">
        {{choice?.cupSize?.code | frankeLabel : store.brand}}
      </div>
      <div class="text-normal text-capitalize text-left">
        {{choice?.beverageFamily?.label | frankeProductName : store.brand : choice?.beverageFamily?.code}}
      </div>
    </div>
    <button type="button" class="flex-fill btn btn-dark btn-primary text-capitalize mr-2"
            [disabled]="!choice.product_id" (click)="startBrew(choice.product_id)">Make My Drink</button>
  </div>
</ng-template>

<!-- <pre class="text-light">
  {{store.currentMenu$ | async | json}}
</pre>

<pre class="text-light">
  {{store.currentChoice$ | async | json}}
</pre> -->
