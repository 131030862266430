import { AbstractAppComponent } from 'src/app/components/AbstractComponent.component';
import { IProductStore } from 'src/app/models/machine.model';
import { ProducStoreFactory } from 'src/app/stores/products.store.factory';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwalService } from 'src/app/services/swal-service.service';
import { IVendor } from 'src/app/models/product.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends AbstractAppComponent {

  IVendor = IVendor;

  constructor(
    private route: ActivatedRoute,
    private swalService: SwalService,
    public factory: ProducStoreFactory
  ) {
    super();
    this.register(
      this.route.queryParams.subscribe( params => {
        const token = params.t;
        if (token == null || token.length == 0) {
          this.swalService.showError("Invalid Token", "Please scan the QR token to select a machine");
          return;
        }
        this.factory.init(token);
        this.clearSubscriptions();
      })
    );
  }
}
