import { Component } from '@angular/core';
import { BrandService } from 'src/app/services/brand.service';
import { ProducStoreFactory } from 'src/app/stores/products.store.factory';
import { AbstractAppComponent } from '../AbstractComponent.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends AbstractAppComponent {

  store: any;
  menu: any;
  starbucks:boolean = false;
  showBack: boolean  = false;
  headerSrc: string;

  constructor(
    factory: ProducStoreFactory,
    private brandService: BrandService
  ) {
    super();

    this.register(
      factory.currentStore$.subscribe((store) => {
        if (store) {
          this.store = store;
          this.store?.currentMenu$.subscribe((m) => (this.menu = m));
          this.starbucks = this.brandService.isStarbucksBranded(store.brand);
          this.headerSrc = `/assets/images/${this.starbucks ? 'starbucks-logo.png' : 'nestle-logo-tmp.jpg'}`;

          this.register(
            this.store?.error$.subscribe((m) => {
              this.showBack = false;
            })
          );
          this.register(
            this.store?.result$.subscribe((m) => {
              this.showBack = false;
            })
          );
          this.register(
            this.store?.currentChoice$.subscribe((m) => {
              this.showBack = !m ? false : true;
              console.log('Setting showback by choice', this.showBack, m);
            })
          );
          this.register(
            this.store?.expiredToken$.subscribe((t) => {
              this.showBack = this.showBack && !t;
              console.log('Setting showback by token', this.showBack, t);
            })
          );
        }
      })
    );
  }

  goBack() {
    this.store.gotoMenu(this.menu.back);
  }

}
