export const environment = {
  production: false,
  selfUrl:"https://www.stag.touchless.nestleprofessional.niotp.com",
  //selfUrl:"https://origin.www.stag.touchless.nestleprofessional.niotp.com",
  apiRootUrl: 'https://api.stag.touchless.nestleprofessional.niotp.com/command',
  //apiRootUrl: 'https://origin.api.stag.touchless.nestleprofessional.niotp.com/command',
  useMockup: false,
  version: '0.1.6.b1',
  internalUrl: 'unknown'
};


